<template>

  <Header />

  <div class="masthead">
    <div class="container">
      <h2 class="animate-up">Explore ways Dispatch can help you stay ahead</h2>
      <h4 class="subtitle animate-up">Looking for inspiration? Get started with one of these templates.</h4>
    </div>
  </div>

  <main>

    <div class="container">

      <template v-if="isBootstrapDataLoading">
        <div class="loading-icon-container">
          <LoadingIcon />
        </div>
      </template>

      <template v-else>

        <section v-if="patchTemplatesByCategory.userContract.length !== 0 ">

          <!-- include empty anchors so old links still work -->
          <div id="smart-contract-event-monitoring" class="offset-anchor" />
          <div id="smart-contract-activity-monitoring" class="offset-anchor" />
          <div id="smart-contracts" class="offset-anchor" />
          <h3>Smart contract activity monitoring</h3>
          <h5>Get alerts for important event emissions and function calls</h5>

          <div class="card-list">
            <PatchTemplateCard
              :key="patchTemplate.id"
              :patchTemplateSlug="patchTemplate.slug"
              v-for="patchTemplate in truncatedTemplates.userContract"
            />
            <div class="excess-templates" :class="{ 'show': shouldShowExcessTemplates.userContract }">
              <PatchTemplateCard
                :key="patchTemplate.id"
                :patchTemplateSlug="patchTemplate.slug"
                v-for="patchTemplate in excessTemplates.userContract"
              />
            </div>
          </div>

          <div class="text-center">
            <button
              class="view-more link"
              @click="shouldShowExcessTemplates.userContract = true"
              v-if="!shouldShowExcessTemplates.userContract && patchTemplatesByCategory.userContract.length > numColumns"
            >
              View all smart contract activity templates
            </button>
          </div>

        </section>

        <section v-if="patchTemplatesByCategory.nft.length !== 0 ">

          <div id="nfts" class="offset-anchor" />
          <h3>NFTs</h3>
          <h5>Stay on top of the action in the world of NFTs</h5>

          <div class="card-list">
            <PatchTemplateCard
              :key="patchTemplate.id"
              :patchTemplateSlug="patchTemplate.slug"
              v-for="patchTemplate in truncatedTemplates.nft"
            />
            <div class="excess-templates" :class="{ 'show': shouldShowExcessTemplates.nft }">
              <PatchTemplateCard
                :key="patchTemplate.id"
                :patchTemplateSlug="patchTemplate.slug"
                v-for="patchTemplate in excessTemplates.nft"
              />
            </div>
          </div>

          <div class="text-center">
            <button
              class="view-more link"
              @click="shouldShowExcessTemplates.nft = true"
              v-if="!shouldShowExcessTemplates.nft && patchTemplatesByCategory.nft.length > numColumns"
            >
              View all NFT templates
            </button>
          </div>

        </section>

        <section v-if="patchTemplatesByCategory.balanceChange.length !== 0 ">

          <div id="balance-monitoring" class="offset-anchor" />
          <h3>Balance monitoring</h3>
          <h5>Get notified of balance changes and threshold crossings</h5>

          <div class="card-list">
            <PatchTemplateCard
              :key="patchTemplate.id"
              :patchTemplateSlug="patchTemplate.slug"
              v-for="patchTemplate in truncatedTemplates.balanceChange"
            />
            <div class="excess-templates" :class="{ 'show': shouldShowExcessTemplates.balanceChange }">
              <PatchTemplateCard
                :key="patchTemplate.id"
                :patchTemplateSlug="patchTemplate.slug"
                v-for="patchTemplate in excessTemplates.balanceChange"
              />
            </div>
          </div>

          <div class="text-center">
            <button
              class="view-more link"
              @click="shouldShowExcessTemplates.balanceChange = true"
              v-if="!shouldShowExcessTemplates.balanceChange && patchTemplatesByCategory.balanceChange.length > numColumns"
            >
              View all balance monitoring templates
            </button>
          </div>

        </section>

        <section v-if="patchTemplatesByCategory.dex.length !== 0 ">

          <div id="defi" class="offset-anchor" />
          <h3>DeFi</h3>
          <h5>Get new pair alerts from your favorite decentralized exchanges</h5>

          <div class="card-list">
            <PatchTemplateCard
              :key="patchTemplate.id"
              :patchTemplateSlug="patchTemplate.slug"
              v-for="patchTemplate in truncatedTemplates.dex"
            />
            <div class="excess-templates" :class="{ 'show': shouldShowExcessTemplates.dex }">
              <PatchTemplateCard
                :key="patchTemplate.id"
                :patchTemplateSlug="patchTemplate.slug"
                v-for="patchTemplate in excessTemplates.dex"
              />
            </div>
          </div>

          <div class="text-center">
            <button
              class="view-more link"
              @click="shouldShowExcessTemplates.dex = true"
              v-if="!shouldShowExcessTemplates.dex && patchTemplatesByCategory.dex.length > numColumns"
            >
              View all DeFi templates
            </button>
          </div>

        </section>

        <button
          @click="openModal()"
          :disabled="isAPIReadOnly"
          class="small secondary suggest-button"
        >
          Suggest a new Patch template
        </button>

      </template>

    </div>
  </main>

  <Footer />

</template>

<script>

  import { mapState } from 'vuex'

  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import PatchTemplateCard from '@/components/etc/PatchTemplateCard.vue'

  import useActivatePatchTemplate from '@/composables/useActivatePatchTemplate'
  import useRandomPatchTemplates from '@/composables/useRandomPatchTemplates'

  export default {
    components: {
      Header,
      Footer,
      LoadingIcon,
      PatchTemplateCard,
    },
    setup() {

      // composables
      const { activatePatchTemplate } = useActivatePatchTemplate()

      // composables
      const { patchTemplatesByCategory } = useRandomPatchTemplates()

      return {
        activatePatchTemplate,
        patchTemplatesByCategory,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly', 'patchTemplates', 'isBootstrapDataLoading']),
      truncatedTemplates() {
        return {
          nft: this.patchTemplatesByCategory.nft.slice(0, this.numColumns),
          dex: this.patchTemplatesByCategory.dex.slice(0, this.numColumns),
          userContract: this.patchTemplatesByCategory.userContract.slice(0, this.numColumns),
          balanceChange: this.patchTemplatesByCategory.balanceChange.slice(0, this.numColumns),
        }
      },
      excessTemplates() {
        return {
          nft: this.patchTemplatesByCategory.nft.slice(this.numColumns),
          dex: this.patchTemplatesByCategory.dex.slice(this.numColumns),
          userContract: this.patchTemplatesByCategory.userContract.slice(this.numColumns),
          balanceChange: this.patchTemplatesByCategory.balanceChange.slice(this.numColumns),
        }
      },
    },
    created() {
      if (this.$route.params.patchTemplateSlug) {
        this.$router.replace({ params: { patchTemplateSlug: null } })
        this.activatePatchTemplate(this.$route.params.patchTemplateSlug)
      }
      this.setNumColumns()
      window.addEventListener('resize', this.setNumColumns)
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.setNumColumns)
    },
    data() {
      return {
        numColumns: 3,
        shouldShowExcessTemplates: {
          nft: false,
          dex: false,
          userContract: false,
          balanceChange: false,
        },
      }
    },
    methods: {
      setNumColumns() {
        this.numColumns = window.innerWidth >= 760 && window.innerWidth < 1280
          ? 3
          : 4
      },
      openModal() {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'SuggestAPatchModal',
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/card-list.styl'

  main
    @apply bg-white

    min-height: calc(100% - 128px)

    .container
      @apply p-4
      @apply pb-16
      @apply mx-auto

  .masthead
    @apply h-72
    @apply mx-auto
    @apply bg-cover
    @apply bg-center

    @apply flex
    @apply items-center

    background-image: url('~@/assets/images/space.jpg')

    .container
      @apply p-4
      @apply mx-auto
      @apply text-center

    h2
      @apply text-white

    h4
      @apply font-normal
      @apply text-primary-200

  section
    @apply mt-16

    h3
      @apply mb-1

    h5
      @apply mb-6
      @apply font-normal
      @apply text-gray-600

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

  .suggest-button
    @apply my-12
    @apply mx-auto

  .view-more
    @apply mt-6

  .excess-templates
    @apply hidden

    &.show
      @apply contents

  .animate-up
    position: relative
    animation: animate-up .4s ease-in-out

    &:nth-child(2)
      animation-duration: .6s

  @keyframes animate-up
    0%
      top: 2rem
      @apply opacity-25

    100%
      @apply top-0
      @apply opacity-100

</style>
